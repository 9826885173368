import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgFooterFacebook: React.FC<IconProps> = (props) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 85.6" {...props}>
      <path
        fill="currentColor"
        d="M26.7 85.6h-16V42.8H0V28h10.7v-8.7C10.7 7.3 13.9 0 28.1 0h11.8v14.8h-7.4c-5.5 0-5.8 2.1-5.8 5.9v7.4H40l-1.6 14.7H26.7v42.8z"
      />
    </Icon>
  )
}
export default SvgFooterFacebook
