import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgFooterYoutube: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 37.3 26.6" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M1 1h35.3v24.6H1z" fill="none" />
      <path
        clipRule="evenodd"
        d="M34.8 3.4c-1-1.7-2-2-4.1-2.2-2.2-.1-7.5-.2-12.1-.2s-9.9.1-12 .2-3.1.4-4.1 2.2C1.5 5.1 1 8.1 1 13.3s.5 8.2 1.5 9.9 2 2 4.1 2.2c2.1.1 7.4.2 12 .2s9.9-.1 12-.2 3.1-.5 4.1-2.2 1.5-4.7 1.5-9.9c.1-5.2-.5-8.2-1.4-9.9z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
      <path d="M15.1 6.8v13l10-6.5z" fill="#fff" />
    </Icon>
  )
}
export default SvgFooterYoutube
