import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgFooterTwitter: React.FC<IconProps> = (props) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 32.5" {...props}>
      <path
        fill="currentColor"
        d="M38.9.6c-1.6.9-3.3 1.6-5.2 2C32.2 1 30.1 0 27.7 0c-4.5 0-8.2 3.7-8.2 8.2 0 .6.1 1.3.2 1.9-6.8-.4-12.9-3.6-16.9-8.6-.7 1.2-1.1 2.6-1.1 4.1 0 2.8 1.4 5.4 3.6 6.8-1.3 0-2.6-.4-3.7-1v.1c0 4 2.8 7.3 6.6 8-.7.2-1.4.3-2.2.3-.5 0-1 0-1.5-.1 1 3.3 4.1 5.6 7.7 5.7-2.9 2.2-6.4 3.5-10.2 3.5-.7 0-1.3 0-2-.1 3.6 2.3 7.9 3.7 12.6 3.7 15.1 0 23.3-12.5 23.3-23.3V8.1c1.6-1.2 3-2.6 4.1-4.2-1.5.7-3.1 1.1-4.7 1.3 1.7-1.1 3-2.7 3.6-4.6z"
      />
    </Icon>
  )
}
export default SvgFooterTwitter
