import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgFooterInstagram: React.FC<IconProps> = (props) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" {...props}>
      <path
        fill="currentColor"
        d="M20 3.6c5.3 0 6 0 8.1.1 1.9.1 3 .4 3.7.7.9.4 1.6.8 2.3 1.5.7.7 1.1 1.4 1.5 2.3.3.7.6 1.8.7 3.7.1 2.1.1 2.7.1 8.1s0 6-.1 8.1c-.1 1.9-.4 3-.7 3.7-.4.9-.8 1.6-1.5 2.3-.7.7-1.4 1.1-2.3 1.5-.7.3-1.8.6-3.7.7-2.1.1-2.7.1-8.1.1s-6 0-8.1-.1c-1.9-.1-3-.4-3.7-.7-.9-.4-1.6-.8-2.3-1.5-.7-.7-1.1-1.4-1.5-2.3-.3-.7-.6-1.8-.7-3.7-.1-2.1-.1-2.8-.1-8.1s0-6 .1-8.1c.1-1.9.4-3 .7-3.7.4-.9.8-1.6 1.5-2.3.7-.7 1.4-1.1 2.3-1.5.7-.3 1.8-.6 3.7-.7 2.1-.1 2.8-.1 8.1-.1M20 0c-5.4 0-6.1 0-8.2.1-2.2.1-3.6.5-4.9.9-1.3.6-2.4 1.2-3.5 2.4C2.2 4.5 1.6 5.6 1 6.9.5 8.2.2 9.6.1 11.8 0 13.9 0 14.6 0 20c0 5.4 0 6.1.1 8.2.1 2.1.4 3.6.9 4.9.5 1.3 1.2 2.4 2.3 3.5 1.1 1.1 2.2 1.8 3.5 2.3 1.3.5 2.7.8 4.9.9 2.2.2 2.9.2 8.3.2s6.1 0 8.2-.1c2.1-.1 3.6-.4 4.9-.9 1.3-.5 2.4-1.2 3.5-2.3 1.1-1.1 1.8-2.2 2.3-3.5.5-1.3.8-2.7.9-4.9.2-2.2.2-2.9.2-8.3s0-6.1-.1-8.2c-.1-2.1-.4-3.6-.9-4.9-.5-1.3-1.2-2.4-2.3-3.5-1.1-1.1-2.2-1.8-3.5-2.3-1.3-.5-2.7-.8-4.9-.9C26.1 0 25.4 0 20 0z"
      />
      <path
        fill="currentColor"
        d="M20 9.7C14.3 9.7 9.7 14.3 9.7 20S14.3 30.3 20 30.3 30.3 25.7 30.3 20 25.7 9.7 20 9.7zm0 17a6.7 6.7 0 110-13.4 6.7 6.7 0 010 13.4z"
      />
      <circle cx={30.7} cy={9.3} r={2.4} fill="currentColor" />
    </Icon>
  )
}
export default SvgFooterInstagram
